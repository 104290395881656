export const useClickTracking = screen => {
  const trackClick = target => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'user_click',
      'screen': screen,
      'target': target
    });
  }

  return { trackClick }
}
