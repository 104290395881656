import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { ensureCurrentUser } from '../../util/data';
import { isSignupEmailTakenError } from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { authenticationInProgress, login, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';

import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedRedirect,
  Page,
  PrivacyPolicy,
  TermsOfService,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import ConfirmSignupForm from './ConfirmSignupForm/ConfirmSignupForm';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';

import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import css from './AuthenticationPage.module.css';
import { getDefaultCountry } from '../../api/locale';
import { useClickTracking } from '../../hooks/useClickTracking';

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.countryCode = getDefaultCountry();
    this.state = {
      // Open sign up page by default for business login path
      isLogin: !props.isBusiness,
      tosModalOpen: false,
      privacyPolicyModalOpen: false,
      authError: Cookies.get('st-autherror') ? JSON.parse(Cookies.get('st-autherror').replace('j:', '')) : null,
      authInfo: Cookies.get('st-authinfo') ? JSON.parse(Cookies.get('st-authinfo').replace('j:', '')) : null,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      confirmError,
      submitSingupWithIdp,
      tab,
      onManageDisableScrolling,
      onLocaleChange,
      currentLocale,
      isBusiness,
      history,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom = this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom || authinfoFrom || null;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const { trackClick } = useClickTracking('authentication')

    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    }
    if (isAuthenticated && currentUserLoaded) {
      return <NamedRedirect name="LandingPage" />;
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="log_in.failed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError)
          ? intl.formatMessage({ id: 'sign_up.email_already_taken' })
          : intl.formatMessage({ id: 'sign_up.failed' })}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError)
          ? intl.formatMessage({ id: 'sign_up.email_already_taken' })
          : intl.formatMessage({ id: 'sign_up.failed' })}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = this.state.isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const handleSubmitSignup = values => {
      const { fname, lname, displayName, country, ...rest } = values;
      const params = {
        firstName: fname.trim(),
        lastName: lname.trim(),
        displayName: displayName.trim(),
        countryCode: country || this.countryCode,
        isBusiness,
        ...rest,
      };
      submitSignup(params);
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const { email: newEmail, firstName: newFirstName, lastName: newLastName, displayName, country, ...rest } = values;

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
        displayName,
      };

      const publicData = { countryCode: country || this.countryCode, isBusiness };
      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? { ...rest } : null;

      submitSingupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...{ publicData },
        ...(!!protectedData && { protectedData }),
      });
    };

    const handleIndividualSignUpClick = () => {
      trackClick('individual_sign_up');
      const path = pathByRouteName('SignupPage', routeConfiguration());
      history.push(path);
      this.setState({ isLogin: false });
    };

    const handleBusinessSignUpClick = () => {
      trackClick('business_sign_up');
      const path = pathByRouteName('BusinessSignupPage', routeConfiguration());
      history.push(path);
      this.setState({ isLogin: false });
    };

    const idp = this.state.authInfo ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          {intl.formatMessage({ id: 'sign_up.confirm_signup_with_idp_title' }, { idp })}
        </h1>

        <span className={css.confirmInfoText}>{intl.formatMessage({ id: 'sign_up.confirm_signup_info_text' })}</span>
        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          onOpenPrivacyPolicy={() => this.setState({ privacyPolicyModalOpen: true })}
          authInfo={this.state.authInfo}
          idp={idp}
        />
      </div>
    );

    // Tabs for SignupForm and LoginForm
    const authenticationForms = (
      <div className={css.content}>
        {loginOrSignupError}

        {this.state.isLogin ? (
          <LoginForm
            className={css.loginForm}
            onSubmit={submitLogin}
            inProgress={authInProgress}
            isBusiness={isBusiness}
            onSignUpClick={handleIndividualSignUpClick}
            onBusinessSignUpClick={handleBusinessSignUpClick}
            authInfo={this.state.authInfo}
            location={location}
            intl={intl}
          />
        ) : (
          <SignupForm
            className={css.signupForm}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
            onOpenPrivacyPolicy={() => this.setState({ privacyPolicyModalOpen: true })}
            onLoginClick={() => this.setState({ isLogin: true })}
            authInfo={this.state.authInfo}
            location={location}
            intl={intl}
            isBusiness={isBusiness}
          />
        )}
      </div>
    );

    const formContent = isConfirm ? confirmForm : authenticationForms;

    const schemaTitle = this.state.isLogin
      ? intl.formatMessage({ id: 'log_in.page_schema' })
      : intl.formatMessage({ id: 'sign_up.page_schema' });

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale} hideSearch />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>{formContent}</div>
            <Modal
              id="AuthenticationPage.tos"
              isOpen={this.state.tosModalOpen}
              onClose={() => this.setState({ tosModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <TermsOfService />
              </div>
            </Modal>
            <Modal
              id="AuthenticationPage.privacyPolicy"
              isOpen={this.state.privacyPolicyModalOpen}
              onClose={() => this.setState({ privacyPolicyModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <PrivacyPolicy />
              </div>
            </Modal>
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
